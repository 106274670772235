<script>
import md5 from "md5";

export default {
  name: "CVTBDropDownInput.vue",
  
  props: {
    dataList: {
      type: [Object, Array]
    },
  
    element: {
      type: [Object, Array]
    },
  
    val: {
      type: String,
    },
  },
  
  data: () => ({
    value: '',
    selectedItem: null,
  }),
  
  computed: {
    dataItems() {
      return this.dataList.filter(item => item[this.element.name].toLowerCase().indexOf(this.value.toLowerCase()) !== -1)
    },
  },
  
  watch: {
    val: {
      handler(){
        this.value = this.val
      },
      deep: true
    },
  },
  
  mounted() {
    this.value = this.val
  },
  
  methods: {
    onSelect(data) {
      // let v = this.dataList.find(item => item[this.element.id] == data);
      // this.value = this.dataList[data][this.element.name]
      this.value = data[this.element.name]
      this.$emit("change", { element: this.element.id, value: this.searchRefValue() })
    },
    
    onChange() {
      this.$emit("change", { element: this.element.id, value: this.value })
    },
    
    searchRefValue(){
      for (let i = 0; i < this.dataList.length; i++){
        // console.log(this.value)
        // console.log(this.value.replace(/\s{2,}/g, ' '))
        if (md5(this.value.replace(/\s{2,}/g, '').trim()) == md5(this.dataList[i][this.element.name].replace(/\s{2,}/g, '').trim()))
          return this.dataList[i]
      }
      return this.value
    },
  
    getkey(item){
      return md5(item[this.element.id])
    },
  },
}
</script>

<template>
  <div class="list-input">
  <v-menu
    style="z-index: 800;"
    class="list-input"
    top
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="list-input__field"
        color="var(--main-orange)"
        outlined
        v-model="value"
        :label="element.caption"
        :type="element.type"
        :rules="element.rules"
        :required="element.required"
        hint="Введите значение или выберете из списка"
        error-messages=""
        v-bind="attrs"
        v-on="on"
        @input="onChange"
      >
        <v-btn
          class="list-input__field__open-btn"
          height="55"
          slot="append"
          v-bind="attrs"
          v-on="on"
          tile
        >
          <v-icon
            color="white"
            @click="onChange"
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </v-text-field>
    </template>
    
    <v-list
      dense
      v-if="dataItems.length > 0"
      class="list-input__list"
    >
      <v-list-item-group
        v-model="selectedItem"
        color="var(--main-orange)"
        @change="onSelect"
      >
            <v-list-item
              v-for="(item, index) in dataItems"
              :key="index"
              @click="onSelect(item)"
            >
              <v-list-item-title class="list-input__title">
                {{item[element.name]}}
              </v-list-item-title>
            </v-list-item>
      </v-list-item-group>
    </v-list>
    
  </v-menu>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.list-input {
  width: 100%;
  z-index: 500000 !important;
  
  &__field {
    
    &__open-btn {
      top: -16.5px;
      margin-bottom: -20px;
      right: -12px;
      width: 70px;
      border-radius: 0 4px 4px 0;
      box-shadow: none;
      background: var(--main-orange) !important;
      
      //&:hover {
      //  background: #696969  !important;
      //}
  
      &:focus,
      &:active {
        background: var(--main-orange) !important;
      }
    }
    
    &:focus,
    &:active &__open-btn  {
      background: var(--main-orange) !important;
    }
    
    //&:hover &__open-btn {
    //  background: #696969 !important;
    //}
  }

  &__title {
    cursor: pointer;
  }
  
  &__list {
    min-height: 0;
    max-height: 400px;
    overflow-y: scroll;
    background: white;
  }
}

.v-text-field:focus .list-input__field__open-btn {
  background: var(--main-orange) !important;
}

</style>
