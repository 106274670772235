<script>
import { order_invoice_form_spec } from "../jsons/order-invoice-form";
import CVTBEdit from "../common/CVTBTextInput.vue";
import CVTBMemo from "../common/CVTBMemo.vue";
import {bus} from "../../router/bus";

export default {
  name: 'OrderInvoiceForm',
  
  props:{
    form_data: {
      type: Object
    },
    ikey: {
      type: String
    }
  },
  
  components:{
    CVTBEdit,
    CVTBMemo,
  },
  
  data: () => ({
    elements: order_invoice_form_spec,
    formdata: {}
  }),
  
  mounted() {
    this.formdata = JSON.parse(JSON.stringify(this.form_data))
  },
  
  methods: {
    OnEditChange(data) {
      this.formdata[data.element] = data.value
    },
    
    onSave() {
      this.$emit('close', 0)
      bus.$emit('update-invoice', {invoice: this.formdata, ikey: this.ikey})
    }
  },
}
</script>

<template>
  <div class="mt-editor-form">
    <v-form
      class="mt-order-fixed-block"
      @submit.prevent="onSave()"
    >
      
      <div class="mt-order-close-wdw">
        <div class="mt-editor-form-actions">
          <v-btn
            color="var(--main-bg-color)"
            text
            type="submit"
          >
            Сохранить
          </v-btn>
          <v-btn
            class="close-btn"
            color="var(--main-bg-color)"
            text
            @click="$emit('close', 0)"
          >
            Закрыть
          </v-btn>
        </div>
        <div class="mt-editor-form-title">Информация об отправителе</div>
      </div>
      <div class="cv-row-div cv-row-margin mt-3"
           v-if="Object.keys(formdata).length > 0">
        
        <CVTBEdit
          :element="elements.invoice_number"
          @input="formdata.invoice_number = $event"
          :val="formdata.invoice_number"
        />
        
        <CVTBEdit
          class="mx-3"
          :element="elements.shipper_email"
          @input="formdata.shipper_email = $event"
          :val="formdata.shipper_email"
        />
        
        <CVTBEdit
          class="ml-3"
          :element="elements.shipper_phone"
          @input="formdata.shipper_phone = $event"
          :val="formdata.shipper_phone"
        />
      </div>
      
      <div class="cv-row-div cv-row-margin" v-if="Object.keys(formdata).length > 0">
        <CVTBEdit
          class="mr-3"
          :element="elements.shipper_fio"
          @input="formdata.shipper_fio = $event"
          :val="formdata.shipper_fio"
        />
        
        <CVTBEdit
          class="mx-3"
          :element="elements.shipper_address"
          @input="formdata.shipper_address = $event"
          :val="formdata.shipper_address"
        />
        
        <CVTBEdit
          class="ml-3"
          :element="elements.invoice_volume"
          @input="formdata.invoice_volume = $event"
          :val="formdata.invoice_volume"
        />
        
        <!--        <CVTBEdit-->
        <!--          class="ml-3"-->
        <!--          :element="elements.gds_cost"-->
        <!--          @input="formdata.gds_cost = $event"-->
        <!--          :val="formdata.gds_cost"-->
        <!--        />-->
      </div>
      
      <CVTBMemo
        :element="elements.shipper_name"
        @change="OnEditChange"
        :val="formdata.shipper_name"
      />
    </v-form>
  </div>
</template>

<style scoped>
.mt-editor-form {
  background-color: #fff;
  margin-bottom: 0.4%;
  padding: 2%;
  border-radius: 5px;
}

.mt-order-close-wdw {
  display: flex;
  flex-direction: row-reverse;
  min-height: 2.5vw;
}

.mt-order-fixed-block {
  width: 98%;
  margin: auto;
  min-height: 20.8541vw;
}
.memo-width{
  height: 70vh;
  min-height: 70vh;
}
.mt-editor-form-title {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  width: 77%;
  margin: auto;
}
.cv-row-div {
  display: flex;
  flex-direction: row;
}
.cv-row-margin{
  margin: auto;
  justify-content: space-between;
}
.mt-editor-form-actions {
  display: flex;
  text-align: right;
  margin: 0.4%;
}
</style>
