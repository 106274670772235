<template>
  <v-textarea
    :name="element.name"
    :label="element.caption"
    :outlined="outlined"
    :rules="element.rules"
    :rows="(element.rows == undefined) ? 3: element.rows"
    color="var(--main-orange)"
    v-model="value"
    @change="OnChange"
  ></v-textarea>
</template>

<script>
export default {
  name: "CVTBMemo.vue",
  
  props: {
    element:{
      type: Object,
      required: true
    },
    
    val:{
      type: [String, Object],
      required: true,
    },
    
    outlined: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  
  data: () => ({
    value: '',
  }),
  
  watch: {
    val: function () {
      this.value = this.val
    },
  },
  
  methods:{
    OnChange(){
      this.$emit("change", { element: this.element.name, value: this.value })
    }
  }
}
</script>

<style scoped>

</style>
