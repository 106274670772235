<template>
  <v-textarea class="cvtb-memo-style"
    :name="element.name"
    :label="element.caption"
    :rows="element.lines"
    :rules="element.rules"
    outlined
    color="var(--main-orange)"
    v-model="value"
    @change="OnChange"
  ></v-textarea>
</template>

<script>
export default {
  name: "CVTBMemo.vue",
  props: {
    element:{
      type: Object
    },
    val:{
      type: String,
    }
  },
  data: () => ({
    value: '',
  }),
  watch: {
    val: function(){
      this.value = this.val
    },
  },
  methods:{
    OnChange(){
      this.$emit("change", { element: this.element.name, value: this.value })
    }
  }
}
</script>

<style scoped>
.cvtb-memo-style{

}
</style>
