<script>
import { order_invoice_form_spec } from "../jsons/order-invoice-form";
import MainInput from "../common/MainInput";
import InputAddCard from "../common/InputAddCard";
export default {
  name: 'OrderInvoiceForm',
  props: {
    cont_cnt: {
      type: [Object, Number],
      default: 1,
    },
    
    invoices: {
      type: [Array, Object],
    },
  },
  
  components:{
    MainInput,
    InputAddCard
  },
  
  data: () => ({
    elements: order_invoice_form_spec,
    valid: false,
    selected_invoice: null,
    addShipperNameInput: false,
    
    shippersDataList: {
      id: "shpr_id",
      name: "shpr_name",
      caption: "Наименование отправителя",
      listname: "warehouse_list",
      listfield: "shpr_name",
      rules: [
        v => !!v || 'Наименование обязателено',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ]
    },
    
    formdata: {
      invoice_number: "",
      shipper_email: "",
      shipper_phone: "",
      shipper_name: "",
      shipper_fio: "",
      shipper_address: "",
      gds_cost: '',
      invoice_volume: '',
    }
  }),
  
  mounted() {
  },
  
  methods: {
    OnEditChange(data) {
      this.formdata[data.element] = data.value
    },

    onSave() {
      this.$refs.form.validate()
      if (this.valid) {
        this.$emit('addInvoice', this.formdata)
      }
    },
  
    OnCheckBoxChange(data){
      this.formdata[data.element] = data.value
    },
  
    clearInvoice() {
      this.formdata.shipper_name = ''
      this.formdata.shipper_email = ''
      this.formdata.shipper_phone = ''
      this.formdata.shipper_fio = ''
      this.formdata.shipper_address = ''
      this.formdata.invoice_volume = ''
    },

    fillInvoice(data) {
      if (typeof(data.value) === 'string') {
        this.formdata.shipper_name = data.value
      } else {
        this.formdata.shipper_name = data.shpr_name
        this.formdata.shipper_email = data.shpr_email
        this.formdata.shipper_phone = data.shpr_phone
        this.formdata.shipper_fio = data.shpr_contact
        this.formdata.shipper_address = data.shpr_addr
      }
    }
  },
}
</script>

<template>
  <div class="mt-editor-form">
    <v-form
      v-model="valid"
      ref="form"
      @submit.prevent="onSave"
      class="mt-order-fixed-block"
    >
      <div class="mt-order-close-wdw">
        <div class="mt-editor-form-actions">
          <v-btn
            color="var(--main-bg-color)"
            text
            type="submit"
          >
            Добавить
          </v-btn>

          <v-btn
            class="close-btn"
            color="var(--main-bg-color)"
            text
            @click="$emit('close', 0)"
          >
            Закрыть
          </v-btn>
        </div>
        <div class="mt-editor-form-title">Информация об отправителе</div>
      </div>
      <div class="cv-grid-div mt-4">
        <div
          class="cv-row-div mt-0 mb-0"
          v-if="invoices.length > 0 && (addShipperNameInput == false || addShipperNameInput == 'add')"
        >
          <v-autocomplete
            v-model="formdata.shipper_name"
            :items="invoices"
            :label="elements.shipper_name.caption"
            return-object
            item-text="shpr_name"
            menu-props="auto"
            outlined
            clearable
            item-color="var(--main-orange)"
            color="var(--main-orange)"
            @change="fillInvoice"
          >
          </v-autocomplete>
  
          <v-btn
            text
            x-small
            class="drop-down-btn"
            elevation="0"
            outlined
            height="56px"
            @click="addShipperNameInput = 'add', clearInvoice()"
          >
            <v-icon x-small class="mt-1">mdi-plus</v-icon>
            добавить нового
            <br/>
            отправителя
          </v-btn>
  
          <div
            v-if="addShipperNameInput === 'add'"
            class="input-add-card"
          >
            <InputAddCard
              :element="elements.shipper_name"
              @close="addShipperNameInput = false"
              @add="formdata.shipper_name = $event, addShipperNameInput = true"
            >
              <div class="input-add-card-form">
                <MainInput
                  type="email"
                  :label="elements.shipper_email.caption"
                  :rules="elements.shipper_email.rules"
                  outlined
                  color="var(--main-orange)"
                  v-model="formdata.shipper_email"
                />
                
                <MainInput
                  type="text"
                  :label="elements.shipper_phone.caption"
                  :rules="elements.shipper_phone.rules"
                  outlined
                  color="var(--main-orange)"
                  v-model="formdata.shipper_phone"
                />
                
                <MainInput
                  type="text"
                  :label="elements.shipper_fio.caption"
                  :rules="elements.shipper_fio.rules"
                  outlined
                  color="var(--main-orange)"
                  v-model="formdata.shipper_fio"
                />
                
                <MainInput
                  type="text"
                  :label="elements.shipper_address.caption"
                  :rules="elements.shipper_address.rules"
                  outlined
                  color="var(--main-orange)"
                  v-model="formdata.shipper_address"
                />
                
                <MainInput
                  type="number"
                  :label="elements.invoice_volume.caption"
                  :rules="elements.invoice_volume.rules"
                  outlined
                  color="var(--main-orange)"
                  v-model="formdata.invoice_volume"
                />
              </div>
           
            </InputAddCard>
          </div>
        </div>
        
        <div
          class="cv-row-div"
          v-else
        >
          <MainInput
            type="text"
            :label="elements.shipper_name.caption"
            :rules="elements.shipper_name.rules"
            outlined
            color="var(--main-orange)"
            v-model="formdata.shipper_name"
          />
          <v-btn
            v-if="invoices.length > 0"
            text
            height="56px"
            class="drop-down-btn"
            elevation="0"
            outlined
            @click="addShipperNameInput = false"
          >
            выбрать из
            <br/>
            списка
          </v-btn>
        </div>
        
        <MainInput
          type="email"
          :label="elements.shipper_email.caption"
          :rules="elements.shipper_email.rules"
          outlined
          color="var(--main-orange)"
          v-model="formdata.shipper_email"
        />

        <MainInput
          type="text"
          :label="elements.shipper_phone.caption"
          :rules="elements.shipper_phone.rules"
          outlined
          color="var(--main-orange)"
          v-model="formdata.shipper_phone"
        />

        <MainInput
          type="text"
          :label="elements.shipper_fio.caption"
          :rules="elements.shipper_fio.rules"
          outlined
          color="var(--main-orange)"
          v-model="formdata.shipper_fio"
        />

        <MainInput
          type="text"
          :label="elements.shipper_address.caption"
          :rules="elements.shipper_address.rules"
          outlined
          color="var(--main-orange)"
          v-model="formdata.shipper_address"
        />
  
        <MainInput
          type="number"
          :label="elements.invoice_volume.caption"
          :rules="elements.invoice_volume.rules"
          outlined
          color="var(--main-orange)"
          v-model="formdata.invoice_volume"
        />
      </div>
      
      <MainInput
        class="cv-grid-div"
        type="text"
        :label="elements.invoice_number.caption"
        :rules="elements.invoice_number.rules"
        outlined
        color="var(--main-orange)"
        v-model="formdata.invoice_number"
      />
    </v-form>
  </div>
</template>

<style scoped lang="scss">
.mt-editor-form {
  margin-bottom: 0.4%;
  background-color: #FFFFFF;
}

.cv-grid-div {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  background-color: #FFFFFF;
  margin-bottom: 0;
  
  &:last-child {
    //padding-bottom: 250px;
  }
}

.cv-grid-inv-number {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  background-color: #FFFFFF;
  margin-bottom: 0;
}

.drop-down-btn {
  text-transform: none;
  font-size: 12px;
  float: right;
  font-weight: 600;
  height: 100%;
  color: white;
  background: var(--main-orange);
  letter-spacing: 0.0192857em;
}


.cv-row-div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.mt-order-close-wdw {
  display: flex;
  flex-direction: row-reverse;
  min-height: 2.5vw;
}

.mt-order-fixed-block {
  width: 98%;
  margin: auto;
  min-height: 20.8541vw;
}

.mt-editor-form-title {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  width: 77%;
  margin: auto;
}

.input-add-card-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.input-add-card {
  position: fixed;
  top: 38vh;
  left: 20%;
  width: 30%;
  min-width: 350px;
  z-index: 500;
  
  @media (max-width: 1320px) {
    top: 30vh;
  }
  
  @media (max-width: 1440px) {
    top: 36vh;
  }
}

.mt-editor-form__select {
  width: 50%;
}

.mt-editor-form-actions {
  display: flex;
  text-align: right;
  margin: 0.4%;
}
</style>
