<script>
import {order_request_form_new} from "../../jsons/order-request-form-new";
import CVTBTextInput from "../../common/CVTBTextInput.vue";
import CVTBDropDownInput from "../../common/CVTBDropDownInput.vue";
import CVTBMemo from "../../common/CVTBMemoInput.vue";
export default {
  name: 'ConsigneeInfoForm',
  components: {
    CVTBMemo,
    CVTBDropDownInput,
    CVTBTextInput
  },
  
  props: {
    rate: {
      type: Object,
      required: true
    },
    
    edit_form: {
      type: Boolean,
      default: false,
      required: false,
    },
    
    cons_name: {
      type: String,
      default: null,
      required: false,
    }
  },
  
  data: () => ({
    elements: order_request_form_new.consignee_info,
    valid: true,
    showLoader: false,
    
    profileData: {},
    innList: [],
    is_low_rate: false,
    payersData: {},
    
    consignesDataList: {
      id: "id",
      name: "shortname",
      caption: "Наименование получателя",
      listname: "consignes_list",
      listfield: "shortname",
      rules: [
        v => !!v || 'Наименование обязательно',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ]
    },
    
    formdata: {
      consignee_address: '',
      consignee_name: '',
      consignee_inn: ''
    }
  }),
  
  mounted() {
    this.getUser()
    if (this.rate.is_nak === 1 && this.$_getsetting('client_id') !== -1) {
      this.getNakInn();
    }
  },
  
  methods: {
    getNakInn() {
      this.$postapi(this.$address + this.$getters.getNakInnList.uri, {
        method: this.$getters.getNakInnList.name,
        key: this.rate.key_orig,
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0){
          this.innList = JSON.parse(data.message).inns
        }
      });
    },
    
    validateForm() {
     return this.$refs.consigneeInfoForm.validate();
    },
    
    sortPayersData() {
      this.payersData = this.profileData.payers_data.filter(obj => this.innList.includes(obj.inn));
    },
    
    getUser() {
      this.showLoader = true
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0){
          this.profileData = JSON.parse(data.message)
          this.payersData = this.profileData.payers_data
          this.isVedexxClient = this.profileData.is_vedexx_client
          if (this.rate.is_nak == 1) {
            this.sortPayersData()
          }
          if (this.edit_form === true) {
            this.fillEditUser()
          }
          this.showLoader = false
        }
      });
    },
    
    fillEditUser() {
      const index = this.profileData.payers_data.findIndex(el => el.name == this.cons_name);
      const data = {
        value: this.profileData.payers_data[index]
      }
      this.fillUserById(data)
    },
    
    openLowRatingForm() {
      let index = this.profileData.payers_data.findIndex(el => el.inn === this.formdata.consignee_inn);
      var queryParams = {
        cid: this.profileData.payers_data[index].id
      }
      let routeData = this.$router.resolve({
        path: "/LowRatingForm",
        query: queryParams
      });
      window.open(routeData.href, '_blank');
    },
    
    fillUserById(data) {
      this.is_low_rate = false
      if (typeof(data.value) === 'string') {
        this.formdata.consignee_name = data.value
      } else {
        this.formdata.consignee_name = data.value.name
        this.formdata.consignee_inn = data.value.inn
        if (data.value.company_rate <= 6 && this.profileData.is_show_notice === 1) {
          this.is_low_rate = true
        }
        this.getUserByInn(data.value.inn)
      }
      if (this.edit_form === true) {
        this.updateEditConsignee(data)
      } else {
        this.updateConsignee()
      }
    },
    
    updateConsignee() {
      this.$emit('updateField', {
        consignee_name: this.formdata.consignee_name,
        consignee_inn: this.formdata.consignee_inn,
      });
    },
    
    updateEditConsignee(data) {
      this.$emit('updateField', {
        cons_id: data.value.id,
        id: data.value.id,
        operation: 0,
        consignee_name: this.formdata.consignee_name,
        consignee_inn: this.formdata.consignee_inn,
      });
    },
    
    getUserByInn(inn) {
      this.$getapi(this.$address + this.$getters.getCompanyByInn.uri + inn)
        .then(data => {
          if (data.companyname){
            this.companyInfo = data
            this.formdata.consignee_address = this.companyInfo.address
            this.$emit('updateField', { consignee_address: this.formdata.consignee_address });
          }
        });
    },
    
    OnEditChange(data) {
      this.formdata[data.element] = data.value
      this.$emit('updateField', { [data.element]: data.value });
    },
  }
}
</script>
<template>
  <v-form
    ref="consigneeInfoForm"
    v-model="valid"
  >
    <v-alert
      v-if="is_low_rate === true"
      dense
      class="request-form__alert mt-3 mb-6"
      type="info"
      color="var(--main-orange)"
    >
      Необходимы дополнительные документы для подтверждения надежности юр. лица. На данный момент согласована работа только по предоплате.
      Чтобы получить отсрочку до выгрузки, необходимо
      <span class="request-form__alert-link" v-if="profileData.hasOwnProperty('payers_data')" @click="openLowRatingForm">заполнить форму </span>
      <router-link v-else to="/AddCompanyData">заполнить форму </router-link>
    </v-alert>
    <div class="cv-grid-div mt-4 mb-3">
      
      <CVTBDropDownInput
        class="mr-3"
        v-if="profileData.hasOwnProperty('payers_data')"
        :element="consignesDataList"
        :val="formdata.consignee_name"
        :dataList="payersData"
        @change="fillUserById"
      />
      
      <CVTBTextInput
        v-else
        @input="formdata.consignee_name = $event"
        :element="elements.consignee_name"
        :val="formdata.consignee_name"
        @change="OnEditChange($event), is_low_rate = true"
      >
        <v-progress-linear
          v-if="showLoader"
          size="24"
          :height="1.5"
          class="form-loader"
          indeterminate
          rounded
          color="var(--main-orange)"
        ></v-progress-linear>
      </CVTBTextInput>
      
      
      <CVTBTextInput
        @input="formdata.consignee_inn = $event"
        :element="elements.consignee_inn"
        :val="formdata.consignee_inn"
        @change="OnEditChange($event)"
      />
    
    </div>
    
    <div>
      <CVTBMemo
        :element="elements.consignee_address"
        @change="OnEditChange"
        :val="formdata.consignee_address"
      />
    </div>
  </v-form>
</template>
<style scoped lang="scss">
.request-form__alert-link {
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    opacity: 0.9;
  }
}

.cv-grid-div {
  display: grid;
  z-index: 400;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  justify-items: flex-start;
  
  &:last-child {
    padding-bottom: 235px;
  }
  
  @media (max-width: 620px) {
    flex-direction: column;
  }
}

.form-loader {
  margin-top: -30px;
  margin-right: -2px;
  margin-left: -2px;
}
</style>
