<script>
import OrderInvoiceCard from "./OrderInvoiceCard.vue"
import md5 from "md5"

export default {
  name: 'OrderInvoiceCardList',
  props:{
    invoice_data: {
      type: [Object, Array]
    }
  },
  components:{
    OrderInvoiceCard,
  },
  data: () => ({
    showInvoiceForm: false
  }),
  methods: {
    getkey(item) {
      return md5(item.shipper_name + item.invoice_number)
    },
  },
}
</script>

<template>
  <div class="order-invoice-list">
    <ul>
    <li v-for="item in invoice_data" :key="getkey(item)">
      <OrderInvoiceCard :invoice_data="item" :ikey="getkey(item)"/>
    </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.order-invoice-list{
  width: 100%;
  overflow-y: scroll;
  height:auto;
  margin-top: 1.62%;
  display: block;
}
ul li {
  display: block;
  float: left;
  overflow: hidden;
  list-style-type: none;
}
ul{
  width: 100%;
}
li {
  width: 48.38%;
}
.btn-container {
  display: block;
  width: 100%;
  &__add-button {
    margin: 40px auto 0;
    display: flex;
  }
}
</style>
