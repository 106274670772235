<script>
import CVTBTextInput from "./CVTBTextInput";
export default {
  components: {
    CVTBTextInput
  },
  
  name: "InputAddCard.vue",
  
  props: {
    element: {
      type: [Object, Array]
    }
  },
  
  data: () => ({
    value: '',
  }),
}
</script>

<template>
  <v-card
    class="add-card"
    elevation="6"
    outlined
  >
    <CVTBTextInput
      @input="value = $event"
      class="pa-0"
      dark
      dense
      :element="element"
      :val="value"
    />
    <slot></slot>
  
    <v-card-actions class="pa-0">
      <v-spacer></v-spacer>
      <v-btn
        class="add-card__btn"
        color="var(--main-bg-color)"
        text
        @click="$emit('add', value)"
      >
        Добавить
      </v-btn>
      <v-btn
        class="add-card__btn"
        color="var(--main-bg-color)"
        text
        type="button"
        @click="$emit('close')"
      >
        Закрыть
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
.add-card {
  background: white;
  width: 100%;
  padding: 24px;
  
  &__input {
   
   }
  
  &__btn {
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.00892857em;
    text-transform: capitalize;
    color: var(--main-bg-color);
  }
}
</style>
