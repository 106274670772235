export const order_request_form_spec =
  {
    name: 'OrderRequestForm',
    method: 'setOrderRequest',
    
    main_info: {
    
    },
    
    consignee_info: {
    
    },
    
    invoice_info: {
    
    },
    
    goods_info: {
    
    },
    
    files: {
    
    },
    
    auto_delivery: {
    
    },
    
    exw_info: {
    
    },
    
    
    invoice_number: {
      caption: "Номер инвойса",
      name: "invoice_number",
      type: "number"
    },
    
    order_num: {
      caption: "Номер заявки",
      name: "order_num",
      type: "text",
      rules: [
        v => (v.length <= 50) || 'Номер должен быть не более 50 символов',
      ],
    },
    
    shipper_name: {
      caption: "Наименование отправителя",
      name: "shipper_name",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Наименование обязательно',
        v => (v && v.length >= 3) || 'Название должно быть не менее 3 символов',
      ],
    },
    
    shipper_email: {
      caption: "E-mail отправителя",
      name: "shipper_email",
      type: "email",
      required: true,
      rules: [
        v => !!v || 'Наименование обязательно',
        v => (v && v.length >= 3) || 'Название должно быть не менее 3 символов',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ],
    },
    
    shipper_phone: {
      caption: "Телефон отправителя",
      name: "shipper_phone",
      type: "number",
      required: true,
    },
  
    is_vtt: {
      caption: "Оформление транзита",
      name: "is_vtt",
      alt: "Требуется оформление таможенного транзита в процессе перевозки.",
      type: "text"
    },
    
    is_hbl: {
      caption: "Домашний коносамент",
      name: "is_hbl",
      alt: "Необходим коносамент от агента для получения релиза в порту.",
      type: "boolean"
    },
    
    is_inshurance: {
      caption: "Дополнительное страхование груза",
      name: "is_inshurance",
      type: "boolean"
    },
    
    is_broker: {
      caption: "Требуется ТО",
      name: "is_broker",
      type: "boolean"
    },
  
    is_car: {
      caption: "Нужна автодоставка",
      name: "is_car",
      alt: "Требуется автодоставка на конечный склад.",
      type: "boolean"
    },
    
    is_protected: {
      caption: "Охрана груза",
      name: "is_protected",
      type: "boolean"
    },
    
    is_exw: {
      caption: "Доставка до порта выхода",
      alt: "Требуется доставка до порта выхода.",
      name: "is_exw",
      type: "boolean"
    },
    
    is_party: {
      caption: "Коносаментная партия",
      name: "is_party",
      alt: "Потребуется оформление коносаментной партии – несколько контейнеров будут отправлены на идентичных условиях и оформлены в одном коносаменте. ",
      type: "boolean",
    },
    
    is_exchange_differences: {
      caption: "Оплата до выгрузки",
      name: "is_exchange_differences",
      type: "boolean",
    },
    
    is_post_paying: {
      caption: "Оплата после выгрузки",
      name: "is_post_paying",
      type: "boolean",
      // disabled: true,
      messages: "* данная опция доступна после выгрузки первого ктк, для ее активации свяжитесь с менеджером Vedexx",
    },
  
    agent_name: {
      caption: "Агент",
      name: "agent_name",
    },
    
    special_condition: {
      caption: "Особые условия",
      name: "special_condition",
      type: "text",
      lines: 2,
      rules: [
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ]
    },
    
    consignee_inn: {
      caption: "Инн получателя",
      name: "consignee_inn",
      type: "number",
      required: true,
      rules: [
        v => !!v || 'Инн обязателен',
        v => (v && v.length >= 10) || 'ИНН должен состоять минимум из 10 символов',
      ],
    },
    
    consignee_name:{
      caption: "Наименование получателя",
      name: "consignee_name",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Наименование обязательно',
        v => (v && v.length >= 3) || 'Название должно быть не менее 3 символов',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ],
    },
    
    consignee_address:{
      caption: "Адрес получателя",
      name: "consignee_address",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Адрес обязателен',
        v => (v && v.length >= 3) || 'Адрес должен быть не менее 10 символов',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ],
    },
    
    warehouse_name: {
      caption: "Наименование склада",
      name: "warehouse_name",
      type: "text",
      required: false,
      rules: [],
    },
    
    warehouse_contact: {
      caption: "Контактное лицо",
      name: "warehouse_contact",
      type: "text",
      required: false,
      rules: [],
    },
    
    warehouse_phone: {
      caption: "Телефон",
      name: "warehouse_phone",
      type: "text",
      required: false,
      rules: [],
    },
    
    warehouse_address: {
      caption: "Адрес склада",
      name: "warehouse_address",
      alt: "Обозначение, адрес и контакты конечного склада для вывоза товара.",
      type: "text",
      required: false,
      rules: []
    },
  
    delivery_from: {
      caption: "Откуда",
      name: "delivery_from",
      alt: "Город отправки",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Необходимо указать адрес город отправки',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ],
    },
    
    delivery_to: {
      caption: "Город доставки",
      name: "delivery_to",
      alt: "Город доставки",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Необходимо указать город доставки',
      ],
      error: false,
      error_message: null,
    },
    
    warehouse_recip_name: {
      caption: "ФИО получателя",
      name: "warehouse_address",
      alt: "Обозначение, адрес и контакты конечного склада для вывоза товара.",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Укажите ФИО получателя',
        v => (v && v.length >= 9) || 'Укажите ФИО целиком',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ],
    },
  
    pickup_address: {
      caption: "Адрес места забора груза",
      name: "pickup_address",
      alt: "Обозначение, адрес и контакты конечного склада для вывоза товара.",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Необходимо указать адрес',
        v => (v && v.length >= 9) || 'Адрес должен быть не менее 10 символов',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ],
    },
    
    place_from_name: {
      caption: "Место отправки",
      name: "place_from_name",
      disabled: true,
      alt: "",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Укажите место отправки',
        v => (v && v.length >= 3) || 'Место отправки должно быть не менее 3 символов',
      ],
    },
    
    gds_name: {
      caption: "Наименование товара",
      name: "gds_name",
      disabled: false,
      alt: "",
      required: true,
      type: "text",
      rules: [
        v => !!v || 'Необходимо указать наименование товара',
        value => {
          const pattern = /[`']/;
          return !pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ],
    },
    
    ready_date: {
      caption: "Дата выхода",
      name: "ready_date",
      disabled: false,
      alt: "",
      type: "date"
    },
    
    gds_gross_weight: {
      caption: "Предполагаемый вес брутто (тонны) на 1 КТК",
      name: "gds_gross_weight",
      disabled: false,
      alt: "",
      type: "number",
      required: true,
      rules: [
        v => !!v || 'Необходимо указать вес товара',
        v => (v.length <= 2) || 'Вес должен быть не более 2-х символова',
      ],
    },
    
    gds_volume: {
      caption: "Предполагаемый объём, м^3 на 1 КТК",
      name: "gds_volume",
      disabled: false,
      alt: "",
      type: "number",
      rules: [
        v => !!v || 'Необходимо указать объём товара',
        v => (v.length <= 5) || 'Объём должен быть не более 5-х символова',
      ],
    },
    
    place_to_name: {
      caption: "Место доставки",
      name: "place_to_name",
      disabled: true,
      alt: "Укажите количество контейнеров в коносаментной партии",
      type: "text",
      required: true,
    },
    
    order_file: {
      caption: "Документы для оформления заявки",
      name: "order_file",
      multiple: true,
      disabled: true,
      alt: "Прикрепите документы для оформления",
    },
    
    port_name: {
      caption: "Порт прибытия",
      name: "port_name",
      disabled: true,
      alt: "Укажите количество контейнеров в коносаментной партии",
      type: "text"
    },
    
    cont_type: {
      caption: "Тип оборудования",
      name: "cont_type",
      disabled: true,
      alt: "Укажите количество контейнеров в коносаментной партии",
    },
    
    line_name: {
      caption: "Линия",
      name: "line_name",
      disabled: true,
      required: true,
      alt: "Укажите количество контейнеров в коносаментной партии",
      type: "text"
    },
    
    exp_name: {
      caption: "Экспедитор в порту",
      name: "exp_name",
      disabled: true,
      alt: "Укажите количество контейнеров в коносаментной партии",
      type: "text"
    },
    
    drop_place: {
      caption: "Место дропа",
      name: "drop_place",
      disabled: true,
      required: true,
      alt: "Укажите количество контейнеров в коносаментной партии",
      type: "text"
    },
    
    cont_cnt: {
      caption: "Количество КТК в партии",
      name: "cont_cnt",
      type: "number",
      alt: "Укажите количество контейнеров в коносаментной партии",
      rules: [
        value => {
          const pattern = /^[^+\-.,e]+$/;
          return pattern.test(value) || "Присутствуют запрещённые символы"
        },
      ],
    },
    
    // agent_id: {
    //   caption: "Агент",
    //   listfield: "agent_name",
    //   keyfield: "agent_id",
    //   multiple: false,
    //   name: "agent_id",
    //   items: [],
    //   default: 0,
    //   reference: 'ClientVDRefAgents',
    //   alt: "",
    // },
    fraight_currency_id: {
      caption: "Валюта оплаты фрахта",
      listfield: "cur_name",
      keyfield: "numcode",
      multiple: false,
      name: "fraight_currency_id",
      items: [],
      default: 0,
      reference: 'ClientVDRefCurrency',
      alt: "Стоимость морской перевозки и валюта оплаты. Условия оплаты уточняйте с менеджером.",
    },
    
    methods: {
      extractValues(data) {
        let result = {}
        if (data.controls.name == data.element) {
          for (let element of Object.keys(data.controls)) {
            if (data.value[data.controls[element].name] != undefined) {
              if (data.value[data.controls[element].name].length > 0) {
                if (data.value[data.controls[element].name][0][data.controls[element].keyfield] != undefined) {
                  for (let i = 0; i < data.value[data.controls[element].name].length; i++) {
                    result[data.controls[element].name] = data.value[data.controls[element].name][i][data.controls[element].keyfield]
                  }
                } else {
                  result[data.controls[element].name] = data.value[data.controls[element].name]
                }
              }
            }
          }
        }
        result.method = data.controls.method
        return { name: data.controls.name, values: result }
      },
    },
  }
