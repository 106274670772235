<script>
export default {
  name: "AppLoader",

  props: {
    size: {
      type: [Number, String],
      default: 24,
      required: false
    }
  }
}
</script>

<template>
  <div class="app-loading" :style="{ width: size + 'px', height: size + 'px' }">
    <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none">
      <path class="circle1" d="M4.02 5.97C2.75 7.65 2 9.74 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2" stroke="#434F5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path class="circle2" d="M5 12C5 15.87 8.13 19 12 19C15.87 19 19 15.87 19 12C19 8.13 15.87 5 12 5" stroke="#434F5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path class="circle3" d="M12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8" stroke="#434F5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<style scoped>
.circle1 {
  animation: rotate1 2s linear infinite;
  transform-origin: center center;
}

.circle2 {
  animation: rotate2 2s linear infinite;
  transform-origin: center center;
}

.circle3 {
  animation: rotate3 2s linear infinite;
  transform-origin: center center;
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotate3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
