<script>
import { bus } from "../../router/bus"
import OrderInvoiceEditForm from "./OrderInvoiceEditForm.vue"
import Modal from "../common/Modal";

export default {
  name: 'OrderInvoiceCard',
  props:{
    invoice_data: {
      type: Object
    },
    
    ikey:{
      type: String
    }
  },
  
  components:{
    OrderInvoiceEditForm,
    Modal,
  },
  
  data: () => ({
    is_show_edit_form: false,
  }),
  
  methods: {
    OnRemove() {
      bus.$emit("removeInvoice", {ikey: this.ikey})
    },
  },
}
</script>

<template>
  <div class="order-invoice-card">
    <div class="order-invoce-card-info">
      <div>Инвойс: {{invoice_data.invoice_number}}</div>
      <div>Отправитель: {{invoice_data.shipper_name}}</div>
      <div class="order-invoice-card-contact">
        <div class="contact-data">e-mail: {{invoice_data.shipper_email}}</div>
        <div class="contact-data">Телефон: {{invoice_data.shipper_phone}}</div>
      </div>
      <div class="contact-data">Адрес: {{invoice_data.shipper_address}}</div>
      <div class="contact-data">ФИО: {{invoice_data.shipper_fio}}</div>
      <div class="contact-data">Объём: {{invoice_data.invoice_volume}}</div>
<!--      <div class="contact-data">Стоимость товара: {{parseFloat(invoice_data.gds_cost).toLocaleString('ru-RU')}} Р</div>-->
    </div>
    
    <div class="order-invoice-card-actions">
      <v-btn
        large
        color="var(--main-orange)"
        class="mb-1"
        @click="is_show_edit_form = true"
      >
        <v-icon color="white">mdi-pencil</v-icon>
      </v-btn>
      
      <v-btn
        large
        color="var(--main-orange)"
        @click="OnRemove"
      >
        <v-icon color="white">mdi-delete-forever</v-icon>
      </v-btn>
    </div>
    
    <Modal
      :show-modal="is_show_edit_form"
    >
      <OrderInvoiceEditForm
        :form_data="invoice_data"
        :ikey="ikey"
        @close="is_show_edit_form = false"
        class="invoice-form"
      />
    </Modal>
  </div>
</template>

<style scoped>
.order-invoice-card{
  display: flex;
  flex-direction: row;
  font-size: 0.7vw;
  padding: 0.805%;
  margin: 0.805% 1.62% 0.805%;
  border: 1px solid #e6eae9;
  border-radius: 10px;
}
.order-invoce-card-info{
  display: flex;
  flex-direction: column;
  margin: 0.805%;
  width: 90%;
}
.order-invoice-card-actions{
  display: flex;
  flex-direction: column;
}
.invoice-form {
  width: 60%;
}

.order-invoice-card-actions-button{
  height: 50%;
  width: 150%;
  background-color: #1976d2;
  color: #fff;
  margin: 1.62%;
}
.order-invoice-card-actions-button:hover{

}
.order-invoice-card-contact{
  display: flex;
  flex-direction: row;
}
.contact-data{
  margin-right: 1.62%;
}
</style>
