<template>
  <v-checkbox
    v-model="value"
    :label="element.caption"
    :disabled="disabled"
    :messages="hint"
    class="cvtb-check-box"
    color="var(--main-bg-color)"
  ></v-checkbox>
</template>

<script>
export default {
  name: "CVTBCheckboxInput.vue",
  props: {
    element: {
      type: Object
    },
    
    val: {
      type: Boolean,
    },
  
    disabled: {
      type: Boolean,
    },
    
    hint: {
      type: String
    },
    
  },
  
  data: () => ({
    // value: false,
  }),
  
  computed: {
    value: {
      get() {
        return this.val
      },
      set(val) {
        this.$emit("selected", { element: this.element.name, value: val})
      }
    },
  },
  
  watch: {
    val: function () {
      this.value = this.val
    },
  },
  
  methods: {
    OnChange() {
      this.$emit("selected", {element: this.element.name, value: this.value})
    }
  },
}
</script>

<style scoped>
.cvtb-check-box{
  height: 40px;
}

</style>
