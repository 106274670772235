export const order_invoice_form_spec =
{
    name: 'OrderInvoiceForm',
    method: 'setOrderRequest',
    invoice_number: {
        caption: "Номер инвойса",
        name: "invoice_number",
        required: true,
        rules: [
            v => !!v || 'Укажите номер',
            // value => {
            //     const pattern = /^[A-Za-z0-9]/;
            //     return pattern.test(value) || 'Разрешены только цифры и буквы латинского алфавита'
            // },
        ],
    },
    shipper_name: {
        caption: "Наименование отправителя",
        name: "shipper_name",
        required: true,
        rules: [
            v => !!v || 'Укажите наименование',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },
    
    shipper_email: {
        caption: "E-mail отправителя",
        name: "shipper_email",
        required: true,
        rules: [
            v => !!v || 'Добавьте email',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },
    
    gds_cost: {
        caption: "Стоимость товара, Р",
        name: "gds_cost",
        required: true,
        type: Number,
        rules: [
            v => !!v || 'Укажите стоимость товара',
            value => {
                const pattern = /^[0-9]/;
                return pattern.test(value) || 'Разрешены только цифры'
            },
        ],
    },
    
    shipper_phone: {
        caption: "Телефон отправителя",
        name: "shipper_phone",
        required: true,
        rules: [
            v => !!v || 'Укажите телефон',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },
    shipper_address: {
        caption: "Адрес отправителя",
        name: "shipper_address",
        required: true,
        rules: [
            v => !!v || 'Укажите адрес',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },
    shipper_fio: {
        caption: "ФИО отправителя",
        name: "shipper_fio",
        required: true,
        rules: [
            v => !!v || 'Укажите ФИО отправителя',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },
    
    invoice_volume: {
        caption: "Предполагаемый объём (тонны)",
        name: "invoice_volume",
        required: false,
    },
    methods: {
        extractValues(data) {
            let result = {}
            if (data.controls.name == data.element) {
                for (let element of Object.keys(data.controls)) {
                    if (data.value[data.controls[element].name] != undefined) {
                        if (data.value[data.controls[element].name].length > 0) {
                            if (data.value[data.controls[element].name][0][data.controls[element].keyfield] != undefined) {
                                for (let i = 0; i < data.value[data.controls[element].name].length; i++) {
                                    result[data.controls[element].name] = data.value[data.controls[element].name][i][data.controls[element].keyfield]
                                }
                            } else {
                                result[data.controls[element].name] = data.value[data.controls[element].name]
                            }
                        }
                    }
                }
            }
            result.method = data.controls.method
            return { name: data.controls.name, values: result }
        },
    },
}
